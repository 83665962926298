import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import iconAdd from "../images/mdi_add_circle_outline.svg"
import iconRenew from "../images/mdi_autorenew.svg"
import iconBug from "../images/mdi_bug_report.svg"
import iconEmoticon from "../images/mdi_insert_emoticon.svg"
import iconLineStyle from "../images/mdi_linestyle.svg"
import { allDispatchFormType, DispatchForm } from "../utils/model"

type ContribuerPageProps = {
  data: {
    forms: allDispatchFormType
  }
}

function ContribuerPage(props: ContribuerPageProps) {
  const forms = props.data?.forms.nodes
    ? props.data?.forms.nodes
        .map(l => {
          let pictoURL = null
          if (l.data.Picto && l.data.Picto.localFiles.length > 0) {
            pictoURL = l.data.Picto.localFiles[0].publicURL
          }

          // let slug = `contribuer/${l.data.Form}`
          return new DispatchForm(
            l.id,
            l.data.Name,
            pictoURL,
            l.data.Form,
            l.data.Prio
          )
        })
        .sort((a, b) => {
          const nameA = a.prio
          const nameB = b.prio
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        })
    : []

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js"
    script.async = true
    document.body.appendChild(script)
    document.body.className = 'page-contribuer'
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout contentClassName="container">
       <SEO title="Vous aussi, contribuez à Shift Your Job"
       description="Proposer un ajout, une modification sur une organisation ou un éditorial. Shift Your Job est un projet hautement collaboratif...qui a besoin de vous" 
       gtmPageType="contribuer" 
       gtmPageName="contribuer" />

      <div className="mx-2 py-16 md:mx-auto md:max-w-2xl lg:max-w-3xl text-primary">
        <h1 className="text-3xl xl:text-5xl leading-none text-primary font-black mb-8">
          Contribuer
        </h1>
        <p>
          Vous souhaitez ajouter une organisation non référencée ou modifier les
          données d’une déjà existante ?
        </p>
        <p className="text-sm my-4">
          Si vous avez vérifié que celle-ci est en cohérence avec la raison
          d’être du projet, n’hésitez pas à remplir les différents champs avec
          le plus d’information possible. 
          <br/>
          Merci de votre aide !
        </p>
        <div className="font-sans font-bold">Je souhaite : </div>

        {forms.map(l => (
          <div>
            <Link
              to={l.slug}
              key={l.id}
              className="font-sans inline-flex shadow-lg items-center text-sm font-bold my-2 bg-white pl-2 pr-8 py-5 rounded-md transition duration-500 ease transition-shadow hover:shadow-xl"
            >
              <span className="w-10 mr-2 text-center">
                <img className="mx-auto" src={l.pictoURL} />
              </span>
              <span>{l.name}</span>
            </Link>
          </div>
        ))}

        <div className="mt-6">
          Pour toute autre demande, vous pouvez utiliser le{" "}
          <a href="/contact" className="custom-underline">
            formulaire&nbsp;de&nbsp;contact
          </a>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContribuerQuery {
    forms: allAirtable(filter: { table: { eq: "DispatchForm" } }) {
      nodes {
        id
        data {
          Name
          Form
          Picto {
            localFiles {
              publicURL
            }
          }
          Prio
        }
      }
    }
  }
`

export default ContribuerPage
